<template>
  <div>
      <div class="intro-y flex items-center mt-8">
          <h2 class="text-lg font-medium mr-auto">Receive Lot Recut</h2>
      </div>
      <div class="grid grid-cols-12 mt-5">
          <div class="intro-y col-span-12 lg:col-span-12">
              <!-- BEGIN: Input -->
              <div class="intro-y box">
                  <div class="p-5">
                      <div class="grid grid-cols-12 gap-x-5">
                          <div class="col-span-12 xl:col-span-4">

                              <div>
                                  <label for="update-profile-form-8" class="form-label">Scan Lot No</label>
                                  <div class="input-group mt-2">

                                      <input id="update-profile-form-6" type="text" tabindex="1" placeholder="Scan Barcode" v-model="scan_lot_no" :class="{'form-control':true,'loading':scanload}" :disabled="scanload" autocomplete="off" @change="scanAndAddPack" />

                                      <button type="button" class="btn btn-primary mr-auto" @click="scanAndAddPack" style="border-radius: 0!important">
                                          <SearchIcon class="w-5 h-5 mr-2" /> Search
                                      </button>
                                      <button type="button" class="btn btn-primary-outline mr-auto" @click="clearSearch('')" style="border-radius: 0!important">
                                          Clear
                                      </button>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <!-- END: Input -->
          </div>
      </div>

      <div class="grid grid-cols-12 mt-5">
          <div class="intro-y col-span-12 lg:col-span-12">
              <!-- BEGIN: Input -->
              <div class="intro-y box">
                  <div class="flex flex-col sm:flex-row items-center justify-between p-5 border-b border-gray-200 dark:border-dark-5">
                      <h2 class="font-medium text-base mr-auto">Packet Last Plan Details</h2>
                      <div class="text-right">
                          <button v-print="'#print-all-barcods'" class="btn btn-primary mr-2">Print All</button>
                          <button class="btn btn-primary mr-2" @click="set_part_details" v-if="packet_item.part_ready == 1 && (user_details.account_id == packet_item.manager_id) && (user_details.user_role == packet_item.manager_type)">Add Planning Part</button>
                          <button class="btn btn-primary mr-2" @click="reset_ls_data" v-if="packet_item.part_ready == 1 && (user_details.account_id == packet_item.manager_id) && (user_details.user_role == packet_item.manager_type)">Reset(LS)</button>
                          <button class="btn btn-primary mr-2" @click="get_ls_edit_details" v-if="packet_item.part_ready == 1 && (user_details.account_id == packet_item.manager_id) && (user_details.user_role == packet_item.manager_type)">Edit(LS)</button>
                      </div>
                  </div>

                  <div class="p-1 pb-10">
                      <div class="col-span-12">
                          <div class="border">
                              <div style="background-color:#fff;">
                                  <table class="mt-3 w-full">
                                      <thead>
                                          <tr class="border-b bg-gray-200 border dark:bg-dark-1">
                                              <th class="py-2 text-center border">Pkt No</th>
                                              <th class="py-2 text-center border">Stock No</th>
                                              <th class="py-2 text-center border">Weight</th>
                                              <th class="py-2 text-center border">Exp. Wt</th>
                                              <th class="py-2 text-center border">Sp</th>
                                              <th class="py-2 text-center border">Cl</th>
                                              <th class="py-2 text-center border">Prt</th>
                                              <th class="py-2 text-center border">Ct</th>
                                              <th class="py-2 text-center border">Pl</th>
                                              <th class="py-2 text-center border">Sym</th>
                                              <th class="py-2 text-center border">Stn</th>
                                              <th class="py-2 text-center border">Fluc</th>
                                              <th class="py-2 text-center border">Tn</th>
                                              <th class="py-2 text-center border w-28">Print</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          <tr v-for="(packet, index) in sub_packets" :key="index" class="border hover:bg-gray-200 p-0 cursor-pointer">
                                              <td class="py-2 font-medium text-center border">{{ packet.number }}</td>
                                              <td class="py-2 font-medium text-center border">{{ packet.vepari_no }}</td>
                                              <td class="py-2 font-medium text-center border">{{ packet.weight }}</td>
                                              <td class="py-2 font-medium text-center border">{{ packet.polish_weight }}</td>
                                              <td class="py-2 font-medium text-center border">{{ packet.shape }}</td>
                                              <td class="py-2 font-medium text-center border">{{ packet.color }}</td>
                                              <td class="py-2 font-medium text-center border">{{ packet.purity }}</td>
                                              <td class="py-2 font-medium text-center border">{{ packet.cut }}</td>
                                              <td class="py-2 font-medium text-center border">{{ packet.polish }}</td>
                                              <td class="py-2 font-medium text-center border">{{ packet.symmetry }}</td>
                                              <td class="py-2 font-medium text-center border">{{ packet.stone }}</td>
                                              <td class="py-2 font-medium text-center border">{{ packet.fluerocent }}</td>
                                              <td class="py-2 font-medium text-center border">{{ packet.tension }}</td>
                                              <td class="py-2 font-medium text-center border">
                                                  <div class="flex justify-between items-center">
                                                    <input
                                                  class="show-code form-check-switch mr-0 ml-3 p-0"
                                                  type="checkbox" v-model="packet.print_status"
                                                />
                                                      <button class="btn btn-primary btn-sm p-1 mr-3" v-print="'#' + packet.number" v-if="packet.print_status == true" @click="update_print_status(packet)">
                                                          <PrinterIcon class="w-5 h-5" />
                                                      </button>
                                                      <button class="btn btn-primary btn-sm p-1 mr-3" v-print="'#' + packet.number" v-if="packet.print_status == false" disabled @click="update_print_status(packet)">
                                                          <PrinterIcon class="w-5 h-5" />
                                                      </button>
                                                      <div class="p-0 m-0 hidden">
                                                          <div :id="packet.number" class="absolute top-0 left-0 p-0 m-0">
                                                              <table class="p-0 m-0 w-full text-black" border="collapse">
                                                                  <tr class="text-center border border-l-0 border-r-0 border-black m-0 p-0">
                                                                      <th colspan="3" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">{{ packet.number }}</th>
                                                                      <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">Lot : {{ packet.vepari_no }}</th>
                                                                      <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">RW : {{ packet.mk_weight ? packet.mk_weight : packet.weight }}</th>
                                                                      <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">PW : {{ packet.polish_weight }}</th>
                                                                  </tr>
                                                                  <tr class="text-center m-0 p-0">
                                                                      <th colspan="9" class="text-center">
                                                                          <VueBarcode :value="packet.barnum" :options="{ displayValue: true,height:27,width:2 }" tag="svg"></VueBarcode>
                                                                      </th>
                                                                  </tr>
                                                                  <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0">
                                                                      <th class="border m-auto border-black">SHP</th>
                                                                      <th class="border m-auto border-black">CL</th>
                                                                      <th class="border m-auto border-black">PRT</th>
                                                                      <th class="border m-auto border-black">CT</th>
                                                                      <th class="border m-auto border-black">PL</th>
                                                                      <th class="border m-auto border-black">SYM</th>
                                                                      <th class="border m-auto border-black">Fl</th>
                                                                      <th class="border m-auto border-black">TN</th>
                                                                      <th class="border m-auto border-black">STN</th>
                                                                  </tr>
                                                                  <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0">
                                                                      <th class="border m-auto border-black">{{ packet.shape }}</th>
                                                                      <th class="border m-auto border-black">{{ packet.color }}</th>
                                                                      <th class="border m-auto border-black">{{ packet.purity }}</th>
                                                                      <th class="border m-auto border-black">{{ packet.cut }}</th>
                                                                      <th class="border m-auto border-black">{{ packet.polish }}</th>
                                                                      <th class="border m-auto border-black">{{ packet.symmetry }}</th>
                                                                      <th class="border m-auto border-black">{{ packet.fluerocent }}</th>
                                                                      <th class="border m-auto border-black">{{ packet.tension }}</th>
                                                                      <th class="border m-auto border-black">{{ packet.stone }}</th>
                                                                  </tr>
                                                              </table>
                                                              <p class="p-0 mt-1 text-black w-full" style="font-size: 7px;line-height: 1;letter-spacing: 3px;font-weight: bold;"> <span class="text-left">{{ packet.created_at }}</span> / <span class="text-right">KODLLIN TECHONOLOGY</span></p>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </td>
                                          </tr>
                                      </tbody>
                                  </table>
                                  <div class="hidden">
                                      <div id="print-all-barcods" class="absolute left-0 top-0 p-0 m-0">

                                          <div style="page-break-before: always;" :class="{'mt-5': index != 0 || (index == 1 && packet_details[0]['ls_done_id'] == null)}" v-for="(packet,index) in packet_details" :key="index">
                                              <table class="p-0 w-full text-black" border="collapse" v-if="(index != 0) || (index == 0 && packet.ls_done_id != null)">
                                                  <tr class="text-center border border-l-0 border-r-0 border-black m-auto">
                                                      <th colspan="3" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">{{ packet.number }}</th>
                                                      <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">Lot : {{ packet.vepari_no }}</th>
                                                      <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">RW : {{ packet.mk_weight ? packet.mk_weight : packet.weight }}</th>
                                                      <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">PW : {{ packet.polish_weight }}</th>
                                                  </tr>
                                                  <tr class="text-center">
                                                      <th colspan="9" class="text-center">
                                                          <VueBarcode :value="packet.barnum" :options="{ displayValue: true,height:27,width:2 }" tag="svg"></VueBarcode>
                                                      </th>
                                                  </tr>
                                                  <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0">
                                                      <th class="border m-auto border-black">SHP</th>
                                                      <th class="border m-auto border-black">CL</th>
                                                      <th class="border m-auto border-black">PRT</th>
                                                      <th class="border m-auto border-black">CT</th>
                                                      <th class="border m-auto border-black">PL</th>
                                                      <th class="border m-auto border-black">SYM</th>
                                                      <th class="border m-auto border-black">Fl</th>
                                                      <th class="border m-auto border-black">TN</th>
                                                      <th class="border m-auto border-black">STN</th>
                                                  </tr>
                                                  <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0">
                                                      <th class="border m-auto border-black">{{ packet.shape }}</th>
                                                      <th class="border m-auto border-black">{{ packet.color }}</th>
                                                      <th class="border m-auto border-black">{{ packet.purity }}</th>
                                                      <th class="border m-auto border-black">{{ packet.cut }}</th>
                                                      <th class="border m-auto border-black">{{ packet.polish }}</th>
                                                      <th class="border m-auto border-black">{{ packet.symmetry }}</th>
                                                      <th class="border m-auto border-black">{{ packet.fluerocent }}</th>
                                                      <th class="border m-auto border-black">{{ packet.tension }}</th>
                                                      <th class="border m-auto border-black">{{ packet.stone }}</th>
                                                  </tr>
                                              </table>
                                              <p class="p-0 mt-1 text-black w-full" style="font-size: 7px;line-height: 1;letter-spacing: 3px;font-weight: bold;"> <span class="text-left">{{ packet.created_at }}</span> / <span class="text-right">KODLLIN TECHONOLOGY</span></p>
                                          </div>
                                      </div>
                                  </div>
                              </div>

                          </div>

                      </div>
                  </div>
              </div>
              <!-- END: Input -->
          </div>
      </div>
      <div class="grid grid-cols-12 mt-5" v-if="((packet_item.main_transaction_status == 1 && packet_item.part_ready == null) || edit_mode == true) && (user_details.account_id == packet_item.manager_id) && (user_details.user_role == packet_item.manager_type) && (packet_item.process_type == 2 || packet_item.process_type == 5)">
          <div class="intro-y col-span-12 lg:col-span-12">
              <!-- BEGIN: Input -->
              <div class="intro-y box">
                  <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
                      <h2 class="font-medium text-base mr-auto">Last Process Details</h2>
                  </div>

                  <div class="p-5">
                      <div class="grid grid-cols-12 gap-x-5">
                          <div class="col-span-12 md:col-span-4">

                              <div>
                                  <label for="update-profile-form-6" class="form-label">Packet No :&nbsp; </label>
                                  <label for="update-profile-form-6 " class="form-label font-bold"> {{ packet_item.number}}</label>
                              </div>
                              <div>
                                  <label for="update-profile-form-6" class="form-label">Issue Lot weight :&nbsp; </label>
                                  <label for="update-profile-form-6 " class="form-label font-bold"> {{ packet_item.issue_weight}} Ct</label>
                              </div>

                              <div>
                                  <label for="update-profile-form-6" class="form-label">Issue To :&nbsp; </label>
                                  <label for="update-profile-form-6 " class="form-label font-bold"> {{ packet_item.employee_first_name + ' '+ packet_item.employee_last_name}}</label>
                              </div>
                              <div>
                                  <label for="update-profile-form-6" class="form-label">Issue At :&nbsp; </label>
                                  <label for="update-profile-form-6 " class="form-label font-bold"> {{ packet_item.created_at}}</label>
                              </div>
                          </div>
                          <div class="col-span-12 md:col-span-4">
                            <div>
                                  <label for="update-profile-form-6" class="form-label">Return Weight :&nbsp;</label>
                                  <input id="update-profile-form-6" type="text" placeholder="Weight in Ct" v-model="menual_total.weight" :class="{'form-control':true,'loading':scanload}" @keypress="$h.preventInvalidInput" onpaste="return false;" @change="calculateGhat" readonly />

                              </div>

                              <div class="mt-2">

                                  <label for="update-profile-form-6" class="form-label">Process Ghat :&nbsp;</label>
                                  <input id="update-profile-form-6" type="text" placeholder="Weight in Ct" v-model="menual_total.ghat_weight" :class="{'form-control':true}" @change="calculateGhat" readonly />
                              </div>
                              <div class="mt-2">

                                  <label for="update-profile-form-6" class="form-label">Process Assortment :&nbsp;</label>
                                  <input id="update-profile-form-6" type="text" placeholder="Weight in Ct" v-model="menual_total.assortment_weight" :class="{'form-control':true}" @change="calculateGhat" readonly />
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <!-- END: Input -->

          </div>

      </div>
      <div class="grid grid-cols-12 mt-5" v-if="(user_details.account_id == packet_item.manager_id) && (user_details.user_role == packet_item.manager_type) && (packet_item.process_type == 5)">
          <div class="intro-y col-span-12 lg:col-span-12">
              <!-- BEGIN: Input -->
              <div class="intro-y box">
                  <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
                      <h2 class="font-medium text-base mr-auto">Packet Plan Details
                          </h2>
                  </div>

                  <div class="p-1 pb-10">
                      <div class="col-span-12">
                          <div class="border">
                              <div style="background-color:#fff;">
                                  <table class="w-full border">
                                      <thead>
                                          <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">#</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Shp</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Clr</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Prt</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Cut</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">PL</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Symm</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Fluc</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">TN</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Stn</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Ratio</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Tabl</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Height</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Repo</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Value</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Back</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">MK Wt</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Polish Wt</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Action</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          <tr v-for="(item,index) in packet_details" :key="index">
                                            <td class="py-2 border-b text-xs lg:text-md text-center border-r"> <span><input type="checkbox" v-model="item.ls_check" @change="getPlanningTotal"></span></td>
                                              <td class="py-2 border-b text-xs lg:text-md text-center border-r"><select class="form-select" v-model="item.shape" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                      <option :value="shape.id" v-for="shape in diamond.shapes" :key="shape.id">{{ shape.shape_name }}</option>
                                                  </select>
                                              </td>
                                              <td class="py-2 border-b text-xs lg:text-md text-center border-r"><select class="form-select" v-model="item.color" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                      <option :value="color.id" v-for="color in diamond.colors" :key="color.id">{{ color.color_name }}</option>
                                                  </select>
                                              </td>
                                              <td class="py-2 border-b text-xs lg:text-md text-center border-r"><select class="form-select" v-model="item.purity" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                      <option :value="purity.id" v-for="purity in diamond.purities" :key="purity.id">{{ purity.purity_name }}</option>
                                                  </select>
                                              </td>
                                              <td class="py-2 border-b text-xs lg:text-md text-center border-r"><select class="form-select" v-model="item.cut" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                      <option :value="cut.id" v-for="cut in diamond.cuts" :key="cut.id">{{ cut.cut_name }}</option>
                                                  </select>
                                              </td>
                                              <td class="py-2 border-b text-xs lg:text-md text-center border-r"><select class="form-select" v-model="item.polish" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                      <option :value="polish.id" v-for="polish in diamond.polish" :key="polish.id">{{ polish.polish_name }}</option>
                                                  </select>
                                              </td>
                                              <td class="py-2 border-b text-xs lg:text-md text-center border-r"><select class="form-select" v-model="item.symmetry" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                      <option :value="symmetry.id" v-for="symmetry in diamond.symmetry" :key="symmetry.id">{{ symmetry.symmetry_name }}</option>
                                                  </select>
                                              </td>
                                              <td class="py-2 border-b text-xs lg:text-md text-center border-r"><select class="form-select" v-model="item.fluerocent" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                      <option :value="fluerocent.id" v-for="fluerocent in diamond.fluerocents" :key="fluerocent.id">{{ fluerocent.fluerocent_name }}</option>
                                                  </select>
                                              </td>
                                              <td class="py-2 border-b text-xs lg:text-md text-center border-r"><select class="form-select" v-model="item.tension" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                      <option :value="tension.id" v-for="tension in diamond.tensions" :key="tension.id">{{ tension.tension_name }}</option>
                                                  </select>
                                              </td>
                                              <td class="py-2 border-b text-xs lg:text-md text-center border-r"><select class="form-select" v-model="item.stone" @change="loadPlanningValues(item)" aria-label="Default select example" disabled>
                                                      <option :value="stone.id" v-for="stone in diamond.stones" :key="stone.id">{{ stone.stone_name }}</option>
                                                  </select>
                                              </td>
                                              <td class="py-2 border-b text-xs lg:text-md text-center border-r"><select class="form-select" v-model="item.ratio" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                      <option :value="ratio.id" v-for="ratio in diamond.ratios" :key="ratio.id">{{ ratio.ratio_name }}</option>
                                                  </select>
                                              </td>
                                              <td class="py-2 border-b text-xs lg:text-md text-center border-r"><select class="form-select" v-model="item.tabl" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                      <option :value="tabl.id" v-for="tabl in diamond.tabls" :key="tabl.id">{{ tabl.tabl_name }}</option>
                                                  </select>
                                              </td>
                                              <td class="py-2 border-b text-xs lg:text-md text-center border-r"><select class="form-select" v-model="item.height" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                      <option :value="height.id" v-for="height in diamond.heights" :key="height.id">{{ height.height_name }}</option>
                                                  </select>
                                              </td>
                                              <td class="py-2 border-b text-xs lg:text-md text-center border-r"><input type="text" class="form-control w-16" disabled v-model="item.r_rate"></td>
                                              <td class="py-2 border-b text-xs lg:text-md text-center border-r"><input type="text" class="form-control w-16" disabled v-model="item.value"></td>
                                              <td class="py-2 border-b text-xs lg:text-md text-center border-r"><input type="text" class="form-control w-16" disabled v-model="item.discount"></td>
                                              <td class="py-2 border-b text-xs lg:text-md text-center border-r"><input type="text" class="form-control w-16" @change="getPlanningTotal" v-model="item.weight"></td>
                                              <td class="py-2 border-b text-xs lg:text-md text-center border-r"><input type="text" class="form-control w-16" @change="loadPlanningValues(item)" v-model="item.polish_weight"></td>
                                              <td>
                                                  <button type="button" class="text-theme-6 flex items-center" v-if="index != 0" tabindex="-1" @click="removeField(item.id)">
                                                      <Trash2Icon class="w-5 h-5 mr-1" tabindex="-1" />
                                                  </button>
                                              </td>
                                          </tr>
                                          <tr class="border-b bg-gray-100 dark:bg-dark-1">
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ menual_total.value }}</td>
                                              <td></td>
                                              <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ menual_total.weight }}</td>
                                              <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ menual_total.polish_weight }}</td>
                                              <td></td>
                                          </tr>
                                          <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                              <td colspan="3">Assortment Weight</td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td class="py-2 border-b text-xs lg:text-md text-center border-r"><input type="text" class="form-control w-16 text-center" @change="getPlanningTotal" v-model="menual_total.assortment_weight"></td>
                                              <td></td>
                                              <td></td>
                                          </tr>
                                          <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                              <td colspan="3">Ghat Weight</td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ menual_total.ghat_weight }}</td>
                                              <td></td>
                                              <td></td>
                                          </tr>
                                      </tbody>
                                  </table>
                              </div>

                          </div>

                      </div>
                      <div class="flex justify-between mt-4">
                          <button type="button" class="btn btn-primary mt-3" @click="addNewPart">
                              Add New Part
                          </button>
                          <button type="button" class="btn btn-primary mt-3" v-if="enable_return" @click="submitReturnRough">
                              Take Return
                          </button>
                      </div>
                  </div>
              </div>
              <!-- END: Input -->
          </div>
      </div>
      <div class="grid grid-cols-12 mt-5" v-else-if="edit_mode == true && (user_details.account_id == packet_item.manager_id) && (user_details.user_role == packet_item.manager_type) && (packet_item.process_type == 2 || packet_item.process_type == 5)">
          <div class="intro-y col-span-12 lg:col-span-12">
              <!-- BEGIN: Input -->
              <div class="intro-y box">
                  <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
                      <h2 class="font-medium text-base mr-auto">Packet Plan Details</h2>
                  </div>

                  <div class="p-1 pb-10">
                      <div class="col-span-12">
                          <div class="border">
                              <div style="background-color:#fff;">
                                  <table class="w-full border">
                                      <thead>
                                          <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">#</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Packet No</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Stock No</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Shp</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Clr</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Prt</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Cut</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">PL</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Symm</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Fluc</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">TN</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Stn</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Ratio</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Tabl</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Height</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Repo</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Value</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Back</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">MK Wt</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Polish Wt</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Action</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          <tr v-for="(item,index) in packet_details" :key="index">
                                              <td class="py-2 border-b text-xs lg:text-md text-center border-r"><span><input type="checkbox" v-model="item.ls_check" @change="getPlanningTotal"></span></td>
                                              <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ item.number ? item.number : '-' }}</td>
                                              <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ item.vepari_no ? item.vepari_no : '-' }}</td>
                                              <td class="py-2 border-b text-xs lg:text-md text-center border-r"><select class="form-select" v-model="item.shape" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                      <option :value="shape.id" v-for="shape in diamond.shapes" :key="shape.id">{{ shape.shape_name }}</option>
                                                  </select>
                                              </td>
                                              <td class="py-2 border-b text-xs lg:text-md text-center border-r"><select class="form-select" v-model="item.color" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                      <option :value="color.id" v-for="color in diamond.colors" :key="color.id">{{ color.color_name }}</option>
                                                  </select>
                                              </td>
                                              <td class="py-2 border-b text-xs lg:text-md text-center border-r"><select class="form-select" v-model="item.purity" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                      <option :value="purity.id" v-for="purity in diamond.purities" :key="purity.id">{{ purity.purity_name }}</option>
                                                  </select>
                                              </td>
                                              <td class="py-2 border-b text-xs lg:text-md text-center border-r"><select class="form-select" v-model="item.cut" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                      <option :value="cut.id" v-for="cut in diamond.cuts" :key="cut.id">{{ cut.cut_name }}</option>
                                                  </select>
                                              </td>
                                              <td class="py-2 border-b text-xs lg:text-md text-center border-r"><select class="form-select" v-model="item.polish" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                      <option :value="polish.id" v-for="polish in diamond.polish" :key="polish.id">{{ polish.polish_name }}</option>
                                                  </select>
                                              </td>
                                              <td class="py-2 border-b text-xs lg:text-md text-center border-r"><select class="form-select" v-model="item.symmetry" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                      <option :value="symmetry.id" v-for="symmetry in diamond.symmetry" :key="symmetry.id">{{ symmetry.symmetry_name }}</option>
                                                  </select>
                                              </td>
                                              <td class="py-2 border-b text-xs lg:text-md text-center border-r"><select class="form-select" v-model="item.fluerocent" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                      <option :value="fluerocent.id" v-for="fluerocent in diamond.fluerocents" :key="fluerocent.id">{{ fluerocent.fluerocent_name }}</option>
                                                  </select>
                                              </td>
                                              <td class="py-2 border-b text-xs lg:text-md text-center border-r"><select class="form-select" v-model="item.tension" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                      <option :value="tension.id" v-for="tension in diamond.tensions" :key="tension.id">{{ tension.tension_name }}</option>
                                                  </select>
                                              </td>
                                              <td class="py-2 border-b text-xs lg:text-md text-center border-r"><select class="form-select" v-model="item.stone" @change="loadPlanningValues(item)" aria-label="Default select example" disabled>
                                                      <option :value="stone.id" v-for="stone in diamond.stones" :key="stone.id">{{ stone.stone_name }}</option>
                                                  </select>
                                              </td>
                                              <td class="py-2 border-b text-xs lg:text-md text-center border-r"><select class="form-select" v-model="item.ratio" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                      <option :value="ratio.id" v-for="ratio in diamond.ratios" :key="ratio.id">{{ ratio.ratio_name }}</option>
                                                  </select>
                                              </td>
                                              <td class="py-2 border-b text-xs lg:text-md text-center border-r"><select class="form-select" v-model="item.tabl" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                      <option :value="tabl.id" v-for="tabl in diamond.tabls" :key="tabl.id">{{ tabl.tabl_name }}</option>
                                                  </select>
                                              </td>
                                              <td class="py-2 border-b text-xs lg:text-md text-center border-r"><select class="form-select" v-model="item.height" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                      <option :value="height.id" v-for="height in diamond.heights" :key="height.id">{{ height.height_name }}</option>
                                                  </select>
                                              </td>
                                              <td class="py-2 border-b text-xs lg:text-md text-center border-r"><input type="text" class="form-control w-16" disabled v-model="item.r_rate"></td>
                                              <td class="py-2 border-b text-xs lg:text-md text-center border-r"><input type="text" class="form-control w-16" disabled v-model="item.value"></td>
                                              <td class="py-2 border-b text-xs lg:text-md text-center border-r"><input type="text" class="form-control w-16" disabled v-model="item.discount"></td>
                                              <td class="py-2 border-b text-xs lg:text-md text-center border-r"><input type="text" class="form-control w-16" @change="getPlanningTotal" v-model="item.weight"></td>
                                              <td class="py-2 border-b text-xs lg:text-md text-center border-r"><input type="text" class="form-control w-16" @change="loadPlanningValues(item)" v-model="item.polish_weight"></td>
                                              <td>
                                                  <button type="button" class="text-theme-6 flex items-center" v-if="index != 0" tabindex="-1" @click="removeField(item.id)">
                                                      <Trash2Icon class="w-5 h-5 mr-1" tabindex="-1" />
                                                  </button>
                                              </td>
                                          </tr>
                                          <tr class="border-b bg-gray-100 dark:bg-dark-1">
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ menual_total.value }}</td>
                                              <td></td>
                                              <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ menual_total.weight }}</td>
                                              <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ menual_total.polish_weight }}</td>
                                              <td></td>
                                          </tr>
                                          <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                              <td colspan="3">Assortment Weight</td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td class="py-2 border-b text-xs lg:text-md text-center border-r"><input type="text" class="form-control w-16 text-center" @change="getPlanningTotal" v-model="menual_total.assortment_weight"></td>
                                              <td></td>
                                              <td></td>
                                          </tr>
                                          <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                              <td colspan="3">Ghat Weight</td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ menual_total.ghat_weight }}</td>
                                              <td></td>
                                              <td></td>
                                          </tr>
                                      </tbody>
                                  </table>
                              </div>

                          </div>

                      </div>
                      <div class="flex justify-between mt-4">
                          <div>

                          </div>
                          <button type="button" class="btn btn-primary mt-3" v-if="enable_return" @click="submitReturnEditRough">
                              Update
                          </button>
                      </div>
                  </div>
              </div>
              <!-- END: Input -->
          </div>
      </div>
      <div id="new-manual-planning" ref="new-manual-planning" class="modal" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog-lg">
              <div class="modal-content">
                  <div class="modal-header">
                      <h2 class="font-medium text-base mr-auto">Add Planning Details</h2>
                  </div>
                  <div class="modal-body">

                      <table class="w-full border">
                          <thead>
                              <tr>
                                  <th>No</th>
                                  <th>Mk Wt</th>
                                  <th>PL Wt</th>
                                  <th>Shp</th>
                                  <th>Clr</th>
                                  <th>Prt</th>
                                  <th>Cut</th>
                                  <th>PL</th>
                                  <th>Symm</th>
                                  <th>Flue</th>
                                  <th>TN</th>
                                  <!-- <th>Stn</th> -->
                                  <th>Ratio</th>
                                  <th>tabl</th>
                                  <th>Height</th>
                                  <th>R Rate ($)</th>
                                  <th>Back (%)</th>
                                  <th>Value ($)</th>
                                  <th></th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr class="border" v-for="(plan,index) in manualPlanning" :key="index">
                                  <td>
                                      <p>{{ index + 1 }}</p>
                                  </td>
                                  <td>
                                      <input v-model="plan.weight" type="text" @input="loadPlanningValuesNew(plan)" class="form-control w-20" placeholder="Weight" @keypress="$h.preventInvalidInput" onpaste="return false;">
                                  </td>
                                  <td>
                                      <input v-model="plan.polish_weight" type="text" @input="loadPlanningValuesNew(plan)" class="form-control w-20" placeholder="Polish Weight" @keypress="$h.preventInvalidInput" onpaste="return false;">
                                  </td>
                                  <td>
                                      <select class="form-select" v-model="plan.shape" @change="loadPlanningValuesNew(plan)" aria-label="Default select example">
                                          <option :value="shape.id" v-for="shape in diamond.shapes" :key="shape.id">{{ shape.shape_name }}</option>
                                      </select>
                                  </td>
                                  <td>
                                      <select class="form-select" v-model="plan.color" @change="loadPlanningValuesNew(plan)" aria-label="Default select example">
                                          <option :value="shape.id" v-for="shape in diamond.colors" :key="shape.id">{{ shape.color_name }}</option>
                                      </select>
                                  </td>
                                  <td>
                                      <select class="form-select" v-model="plan.purity" @change="loadPlanningValuesNew(plan)" aria-label="Default select example">
                                          <option :value="shape.id" v-for="shape in diamond.purities" :key="shape.id">{{ shape.purity_name }}</option>
                                      </select>
                                  </td>
                                  <td>
                                      <select class="form-select" v-model="plan.cut" @change="loadPlanningValuesNew(plan)" aria-label="Default select example">
                                          <option :value="shape.id" v-for="shape in diamond.cuts" :key="shape.id">{{ shape.cut_name }}</option>
                                      </select>
                                  </td>
                                  <td>
                                      <select class="form-select" v-model="plan.polish" @change="loadPlanningValuesNew(plan)" aria-label="Default select example">
                                          <option :value="shape.id" v-for="shape in diamond.polish" :key="shape.id">{{ shape.polish_name }}</option>
                                      </select>
                                  </td>
                                  <td>
                                      <select class="form-select" v-model="plan.symmetry" @change="loadPlanningValuesNew(plan)" aria-label="Default select example">
                                          <option :value="shape.id" v-for="shape in diamond.symmetry" :key="shape.id">{{ shape.symmetry_name }}</option>
                                      </select>
                                  </td>
                                  <td>
                                      <select class="form-select" v-model="plan.fluerocent" @change="loadPlanningValuesNew(plan)" aria-label="Default select example">
                                          <option :value="shape.id" v-for="shape in diamond.fluerocents" :key="shape.id">{{ shape.fluerocent_name }}</option>
                                      </select>
                                  </td>
                                  <td>
                                      <select class="form-select" v-model="plan.tension" @change="loadPlanningValuesNew(plan)" aria-label="Default select example">
                                          <option :value="shape.id" v-for="shape in diamond.tensions" :key="shape.id">{{ shape.tension_name }}</option>
                                      </select>
                                  </td>
                                  <td>
                                      <select class="form-select" v-model="plan.ratio" @change="loadPlanningValuesNew(plan)" aria-label="Default select example">
                                          <option :value="ratio.id" v-for="ratio in diamond.ratios" :key="ratio.id">{{ ratio.ratio_name }}</option>
                                      </select>
                                  </td>
                                  <td>
                                      <select class="form-select" v-model="plan.tabl" @change="loadPlanningValuesNew(plan)" aria-label="Default select example">
                                          <option :value="tabl.id" v-for="tabl in diamond.tabls" :key="tabl.id">{{ tabl.tabl_name }}</option>
                                      </select>
                                  </td>
                                  <td>
                                      <select class="form-select" v-model="plan.height" @change="loadPlanningValuesNew(plan)" aria-label="Default select example">
                                          <option :value="height.id" v-for="height in diamond.heights" :key="height.id">{{ height.height_name }}</option>
                                      </select>
                                  </td>
                                  <td>
                                      <input v-model="plan.r_rate" type="text" disabled class="form-control w-20" placeholder="R rate" @keypress="$h.preventInvalidInputInt" onpaste="return false;">
                                  </td>
                                  <td>
                                      <input v-model="plan.discount" type="text" disabled class="form-control w-20" placeholder="Back" @keypress="$h.preventInvalidInputInt" onpaste="return false;">
                                  </td>
                                  <td>
                                      <input v-model="plan.value" type="text" disabled @input="getPlanningTotalNew" class="form-control w-20" placeholder="Value" @keypress="$h.preventInvalidInputInt" onpaste="return false;">
                                  </td>
                                  <td>
                                      <button type="button" class="text-theme-6 flex items-center" tabindex="-1" @click="removeFieldNew(plan.id)">
                                          <Trash2Icon class="w-5 h-5 mr-1" tabindex="-1" />
                                      </button>
                                  </td>
                              </tr>
                          </tbody>
                          <tbody>
                              <tr class="border">
                                  <td>
                                  </td>
                                  <td :class="{'text-theme-6' : $h.myFloat(menual_total_planning.weight ? menual_total_planning.weight : 0) > $h.myFloat(sub_part.weight)}">
                                      <p>{{ menual_total_planning.weight }} / {{ sub_part.weight }}</p>
                                  </td>
                                  <td :class="{'text-theme-6' : $h.myFloat(menual_total_planning.polish_weight ? menual_total_planning.polish_weight : 0) > $h.myFloat(sub_part.weight)}">
                                      <p>{{ menual_total_planning.polish_weight }}</p>
                                  </td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>
                                      <p>{{ $h.myFloat(this.menual_total_planning.value ? this.menual_total_planning.value : 0) }}</p>
                                  </td>
                              </tr>
                          </tbody>

                      </table>

                      <div>
                          <button type="button" @click="AddFieldPlan" class="btn btn-primary ml-5 mt-5 mb-5 mr-auto">
                              New Part Planning
                          </button>
                      </div>

                  </div>

                  <div class="modal-footer text-right">
                      <button type="button" data-dismiss="modal" class="btn btn-outline-secondary w-32 mr-1">
                          Cancel
                      </button>
                      <button type="button" @click="submitManualPlanning" :disabled="this.loading" class="btn btn-primary">Save Details
                          <LoadingIcon v-if="this.loading" icon="oval" color="white" class="w-4 h-4 ml-2" />
                      </button>
                  </div>
              </div>
          </div>
      </div>
  </div>
  </template>

  <script>
  /* eslint-disable */
  import {
      defineComponent
  } from 'vue'
  import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
  import {
      BASE_URL
  } from '@/constants/config.js'
  import axios from 'axios'
  import datetime from 'vuejs-datetimepicker';
  import Tabulator from 'tabulator-tables'
  // import BarcodeGenerator from "@/components/barcode-generator/Main.vue";
  import VueBarcode from '@chenfengyuan/vue-barcode';

  export default defineComponent({
      components: {
          DarkModeSwitcher,
          datetime,
          VueBarcode
      },
      props: {
          rough_id: {
              type: String,
              default: ""
          }
      },

      data() {
          return {
              packet_details: [],
              packet_item: [],
              sub_packets: [],
              edit_mode: false,
              user_details: [],
              manualPlanning: [{
                  id: 1,
                  part_no: '',
                  weight: '',
                  polish_weight: '',
                  color: '',
                  cut: '',
                  purity: '',
                  polish: '',
                  shape: '',
                  symmetry: '',
                  fluerocent: '',
                  tension: '',
                  stone: '',
                  ratio: '',
                  tabl: '',
                  r_rate: '',
                  value: '',
                  height: '',
                  discount: '',
              }],
              menual_total: {
                  rough_weight: 0,
                  weight: 0,
                  polish_weight: 0,
                  value: 0,
                  return_weight: 0,
                  ghat_weight: 0,
                  assortment_weight: 0,
              },
              menual_total_planning: {
                  rough_weight: 0,
                  weight: 0,
                  polish_weight: 0,
                  value: 0,
                  return_weight: 0,
                  ghat_weight: 0,
                  assortment_weight: 0,
              },
              total_items: 1,
              ls_receive: false,
              last_pack_id: '',
              scanload: false,
              showreturndetails: false,
              enable_return: false,
              loading: false,
              scan_lot_no: '',
              sub_part: {
                  packet_no: '',
                  vepari_no: '',
                  packet_id: '',
                  weight: '',
                  new_weight: '',
                  loading: false
              },

              diamond: {
                  colors: [],
                  cuts: [],
                  purities: [],
                  symmetry: [],
                  polish: [],
                  fluerocents: [],
                  tensions: [],
                  stones: [],
                  shapes: [],
                  heights: [],
                  ratios: [],
                  tabls: [],
              }
          }
      },
      computed: {

      },
      mounted() {
          this.getDiamontData();

          this.user_details = this.$store.state.user.currentUser
      },

      methods: {
          async scanAndAddPack() {
              let _this = this;
              _this.clearSearch(this.scan_lot_no);
              this.scanload = true;

              let promise = axios({
                  url: "api/process/scan_lot_rough_to_polish_ls",
                  method: "post",
                  data: {
                      barcode: this.scan_lot_no,
                  },
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  },
              });
              return promise
                  .then((result) => result.data)
                  .then((data) => {

                    this.edit_mode = false
                    this.packet_details = data.plan_details
                    this.packet_item = data.packet_details
                    this.sub_packets = data.stock_items

                      if (this.packet_item.main_transaction_status == 1) {
                      } else {

                          if (this.packet_item) {
                              this.packet_item.cr_weight = this.packet_item.weight_loss
                          }
                      }

                      this.getPlanningTotal()
                      this.enable_return = true;
                      this.scanload = false;

                  })
                  .catch((_error) => {
                      this.scanload = false;
                      return [];
                  });
          },
          async get_ls_edit_details() {
              let _this = this;
              _this.clearSearch(this.scan_lot_no);
              this.scanload = true;

              //get lot details
              const data = {
                  barcode: this.scan_lot_no,
              };
              let url_post = '';
              if (this.scan_lot_no != '') {
                  url_post = "api/process/get_edit_ls_details"
              } else {
                  url_post = "api/process/get_edit_ls_details"
              }
              let promise = axios({
                  url: url_post,
                  method: "post",
                  data: data,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  },
              });
              return promise
                  .then((result) => result.data)
                  .then((data) => {

                    this.edit_mode = true
                      this.packet_details = data.plan_details
                      this.packet_item = data.packet_details
                      this.sub_packets = data.sub_packets

                      if (this.edit_mode == true) {

                          if (data.plan_details.length > 0) {

                              this.getPlanningTotal()

                              this.enable_return = true;

                          } else {

                              _this.clearSearch('');
                              this.$swal("Warning!", "Packet Details Not Found!", "warning");

                          }

                      } else {

                          if (this.packet_item) {
                              this.packet_item.cr_weight = this.packet_item.weight_loss
                          }
                      }
                      this.scan_load = false;

                      this.scanload = false;

                  })
                  .catch((_error) => {
                      this.scanload = false;
                      return [];
                  });
          },
          clearSearch(lot_no) {
              this.scan_lot_no = lot_no;
              this.packet_details = [];
              this.packet_item = [];
              this.menual_total.weight = 0;
              this.menual_total.polish_weight = 0;
              this.menual_total.value = 0;
              this.menual_total.ghat_weight = 0;
              this.menual_total.assortment_weight = 0;
              this.scanload = false
              this.edit_mode = false
              this.showreturndetails = false
              this.enable_return = false
          },
          getDiamontData() {
              axios({
                  url: 'api/master/get_all_parameters',
                  method: 'post',
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: 'Bearer ' + localStorage.getItem('token')
                  },
                  config: {
                      headers: {
                          'Accept': 'application/json'
                      }
                  }
              }).then((res) => {
                  this.diamond.colors = res.data.color
                  this.diamond.cuts = res.data.cut
                  this.diamond.purities = res.data.purity
                  this.diamond.polish = res.data.polish
                  this.diamond.shapes = res.data.shape
                  this.diamond.symmetry = res.data.symmetry
                  this.diamond.fluerocents = res.data.fluerocent
                  this.diamond.tensions = res.data.tension
                  this.diamond.stones = res.data.stone
                  this.diamond.heights = res.data.height
                  this.diamond.tabls = res.data.tabl
                  this.diamond.ratios = res.data.ratio
              })
          },
          removeField(id) {
              this.$swal({
                  title: "Are you sure?",
                  text: "You are about to delete a item!",
                  type: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Delete",
                  showLoaderOnConfirm: true,
                  cancelButtonText: "Cancel",

                  allowOutsideClick: () => !$Swal.isLoading()
              }).then(result => {
                  if (result.isConfirmed) {
                      var i = this.packet_details.length;
                      while (i--) {
                          if (this.packet_details[i]['id'] == id) {
                              this.packet_details.splice(i, 1);
                          }
                      }
                      this.getPlanningTotal()
                  }
              });
          },
          loadPlanningValues(plan) {
              if (plan.polish_weight != '' && plan.weight != '') {
                  if (parseFloat(plan.polish_weight) > parseFloat(plan.weight)) {

                      this.$swal("Warning!", "Polish Must Less then Makeable Weight!", "warning")
                      var i = this.packet_details.length;
                      while (i--) {
                          if (this.packet_details[i]['id'] == plan.id) {
                              this.packet_details[i]['polish_weight'] = ''
                              return
                          }
                      }
                  }
              }
              this.getPlanningTotal()
              if (plan.polish_weight != '' && plan.color != '' && plan.cut != '' && plan.purity != '' && plan.polish != '' && plan.shape != '' && plan.symmetry != '' && plan.fluerocent != '' && plan.height != '' && plan.ratio != '' && plan.tabl != '') {

                  let shape_name = ''
                  var i = this.diamond.shapes.length;
                  while (i--) {
                      if (this.diamond.shapes[i]['id'] == plan.shape) {
                          shape_name = this.diamond.shapes[i]['shape_group']
                      }
                  }
                  let params = {
                      color: plan.color,
                      cut: plan.cut,
                      purity: plan.purity,
                      polish: plan.polish,
                      symmetry: plan.symmetry,
                      fluerocent: plan.fluerocent,
                      tension: plan.tension,
                      height: plan.height,
                      ratio: plan.ratio,
                      tabl: plan.tabl,
                      shape: plan.shape,
                      shape_group: shape_name,
                      polish_weight: plan.polish_weight,
                      stone: plan.stone,
                  }
                  axios({
                      url: 'api/plan/get_plan_values',
                      method: 'post',
                      baseURL: BASE_URL,
                      data: params,
                      headers: {
                          Authorization: 'Bearer ' + localStorage.getItem('token')
                      },
                      config: {
                          headers: {
                              'Accept': 'application/json'
                          }
                      }
                  }).then((res) => {
                      let rap_value = res.data.rape_value
                      let back = res.data.back

                      var i = this.packet_details.length;
                      while (i--) {
                          if (this.packet_details[i]['id'] == plan.id) {
                              this.packet_details[i]['r_rate'] = parseFloat(rap_value * plan.polish_weight).toFixed(2)
                              this.packet_details[i]['discount'] = back
                              this.packet_details[i]['value'] = this.packet_details[i]['r_rate'] - (this.packet_details[i]['r_rate'] * back / 100)
                          }
                      }
                      this.getPlanningTotal()
                  })
              }

          },
          getPlanningTotal() {
              let length = this.packet_details.length

              this.menual_total.weight = 0
              this.menual_total.polish_weight = 0
              this.menual_total.value = 0

              for (let i = 0; i < length; i++) {
                if (i == (length - 1)) {
                    this.last_pack_id = this.packet_details[i]['id']
                }

                if(this.packet_details[i]['ls_check'] == true) {

                  this.menual_total.weight = parseFloat(parseFloat(this.menual_total.weight) + parseFloat(this.packet_details[i]['weight'] ? this.packet_details[i]['weight'] : 0)).toFixed(4)
                  this.menual_total.polish_weight = parseFloat(parseFloat(this.menual_total.polish_weight) + parseFloat(this.packet_details[i]['polish_weight'] ? this.packet_details[i]['polish_weight'] : 0)).toFixed(4)
                  this.menual_total.value = parseFloat(parseFloat(this.menual_total.value) + parseFloat(this.packet_details[i]['value'] ? this.packet_details[i]['value'] : 0)).toFixed(2)

                  if(parseFloat(this.menual_total.weight) > parseFloat(this.packet_item.issue_weight))
                  {
                    this.packet_details[i]['weight'] = "";

                    this.getPlanningTotal();
                    this.Swal("LS Weight must be less then Issue Weight");
                  }
                }

              }
              this.menual_total.ghat_weight = parseFloat((this.packet_item.issue_weight ? this.packet_item.issue_weight : 0) - (parseFloat((this.menual_total.weight ? this.menual_total.weight : 0)) + parseFloat((this.menual_total.assortment_weight ? this.menual_total.assortment_weight : 0)))).toFixed(4)

          },
          addNewPart() {
              this.last_pack_id = this.last_pack_id + 1
              this.packet_details.push({
                  id: this.last_pack_id,
                  packet_id: '',
                  plan_no: '',
                  weight: '',
                  polish_weight: '',
                  ls_check: false,
                  color: '',
                  cut: '',
                  purity: '',
                  polish: '',
                  shape: '',
                  symmetry: '',
                  fluerocent: '',
                  tension: '',
                  stone: this.packet_item.stone,
                  height: '',
                  ratio: '',
                  tabl: '',
                  r_rate: '',
                  value: '',
                  discount: '',
              })
          },
          submitReturnRough() {
              let submit = true

              this.packet_details.forEach(plan => {
                if (!(plan.weight) || !(plan.polish_weight) || !(plan.color) || !(plan.cut) || !(plan.purity) || !(plan.polish) || !(plan.shape) || !(plan.symmetry) || !(plan.fluerocent) || !(plan.height) || !(plan.ratio) || !(plan.tabl)) {
                      this.$swal("Warning!", "Planning Fields are Empty !", "warning")
                      submit = false
                  }
              });
              if (submit == true) {

                  this.$swal({
                      title: "Are you sure?",
                      text: "You are You Want To Return!",
                      type: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Submit",
                      showLoaderOnConfirm: true,
                      cancelButtonText: "Cancel",

                      allowOutsideClick: () => !$Swal.isLoading()
                  }).then(result => {
                      if (result.isConfirmed) {

                          let params = {
                              plan_details: this.packet_details,
                              menual_total: this.menual_total,
                              packet_id: this.packet_item.id,
                              trans_id: this.packet_item.transaction_id
                          }

                          let api_url = '';

                          if(this.packet_item.process_type == 2) {
                            api_url = 'api/process/return_ls_receive_party'
                          } else if(this.packet_item.process_type == 5) {
                            api_url = 'api/process/return_ls_receive_party'

                          }
                          let promise = axios({
                              url: api_url,
                              method: "post",
                              data: params,
                              baseURL: BASE_URL,
                              headers: {
                                  Authorization: "Bearer " + localStorage.getItem("token")
                              },
                              config: {
                                  headers: {
                                      Accept: "application/json"
                                  }
                              },
                          });
                          return promise
                              .then((result) => result.data)
                              .then((data) => {
                                  if (data.status == 'success') {
                                      this.$swal("Success!", "Item Returned to Manager.", "success");
                                      this.scanAndAddPack()
                                  } else {

                                      this.$swal("Warning!", "Somthing Wants Wrong!", "warning");
                                  }
                              })
                      }
                  });

              }
          },
          submitReturnEditRough() {
              let submit = true

              this.packet_details.forEach(plan => {
                  if (!(plan.weight) || !(plan.polish_weight) || !(plan.color) || !(plan.cut) || !(plan.purity) || !(plan.polish) || !(plan.shape) || !(plan.symmetry) || !(plan.fluerocent) || !(plan.height) || !(plan.ratio) || !(plan.tabl)) {
                      this.$swal("Warning!", "Planning Fileds are Empty !", "warning")
                      submit = false
                  }
              });
              if (submit == true) {

                this.$swal({
                    title: "Are you sure?",
                    text: "You are You Want To Update Packets!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Submit",
                    showLoaderOnConfirm: true,
                    cancelButtonText: "Cancel",

                    allowOutsideClick: () => !$Swal.isLoading()
                }).then(result => {
                    if (result.isConfirmed) {

                        let params = {
                            plan_details: this.packet_details,
                            menual_total: this.menual_total,
                            packet_id: this.packet_item.id,
                            trans_id: this.packet_item.transaction_id
                          }

                        let api_url = ''
                        if(this.packet_item.process_type == 2) {
                            api_url = 'api/process/update_ls_receive_internal'
                          } else if(this.packet_item.process_type == 5) {
                            api_url = 'api/process/update_ls_receive'

                          }

                        let promise = axios({
                            url: api_url,
                            method: "post",
                            data: params,
                            baseURL: BASE_URL,
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("token")
                            },
                            config: {
                                headers: {
                                    Accept: "application/json"
                                }
                            },
                        });
                        return promise
                            .then((result) => result.data)
                            .then((data) => {
                                if (data.status == 'success') {
                                    this.$swal("Success!", "Item Returned to Manager.", "success");
                                    this.scanAndAddPack()
                                } else {

                                    this.$swal("Warning!", "Soothing Wants Wrong!", "warning");
                                }
                            })
                    }
                });

            }
          },
          set_part_details() {
              cash('#new-manual-planning').modal('show')
              this.sub_part.packet_no = this.packet_item.number
              this.sub_part.vepari_no = this.packet_item.vepari_no
              this.sub_part.packet_id = this.packet_item.id
              this.sub_part.weight = this.packet_item.weight_loss

              this.addNewPlanning(this.sub_part.packet_id)
          },
          reset_ls_data() {
            this.$swal({
                      title: "Are you sure?",
                      text: "You are You Want To Reset LS!",
                      type: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Submit",
                      showLoaderOnConfirm: true,
                      cancelButtonText: "Cancel",

                      allowOutsideClick: () => !$Swal.isLoading()
                  }).then(result => {
                      if (result.isConfirmed) {

                          let params = {
                              packet_id: window.btoa(this.packet_item.id),
                          }

                          let api_url = 'api/process/cancel_plan_done'

                          let promise = axios({
                              url: api_url,
                              method: "post",
                              data: params,
                              baseURL: BASE_URL,
                              headers: {
                                  Authorization: "Bearer " + localStorage.getItem("token")
                              },
                              config: {
                                  headers: {
                                      Accept: "application/json"
                                  }
                              },
                          });
                          return promise
                              .then((result) => result.data)
                              .then((data) => {
                                  if (data.status == 'success') {
                                      this.$swal("Success!", data.message, "success");
                                      this.scanAndAddPack()
                                  } else {

                                      this.$swal("Warning!", data.message, "warning");
                                  }
                              }).catch((error) => {
                                this.$swal("Warning!", "Something went wrong.", "warning");
                              })
                      }
                  });
          },
          AddFieldPlan() {
              this.total_items++
              this.manualPlanning.push({
                  id: this.total_items,
                  part_no: '',
                  weight: '',
                  polish_weight: '',
                  color: '',
                  cut: '',
                  purity: '',
                  polish: '',
                  shape: '',
                  symmetry: '',
                  fluerocent: '',
                  tension: '',
                  stone: this.packet_item.stone,
                  ratio: '',
                  tabl: '',
                  r_rate: '',
                  value: '',
                  height: '',
                  discount: '',
              });
              console.log(this.total_items);

          },
          removeFieldNew(id) {
              this.$swal({
                  title: "Are you sure?",
                  text: "You are about to delete a item!",
                  type: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Delete",
                  showLoaderOnConfirm: true,
                  cancelButtonText: "Cancel",

                  allowOutsideClick: () => !this.$swal.isLoading()
              }).then(result => {
                  if (result.isConfirmed) {
                      var i = this.manualPlanning.length;
                      while (i--) {
                          if (this.manualPlanning[i]['id'] == id) {
                              this.manualPlanning.splice(i, 1);
                          }
                      }
                  }
              });
          },
          getPlanningTotalNew() {
              let length = this.manualPlanning.length

              this.menual_total_planning.weight = 0
              this.menual_total_planning.polish_weight = 0
              this.menual_total_planning.value = 0

              for (let i = 0; i < length; i++) {
                  this.menual_total_planning.weight = parseFloat(parseFloat(this.menual_total_planning.weight) + parseFloat(this.manualPlanning[i]['weight'] ? this.manualPlanning[i]['weight'] : 0)).toFixed(4)
                  this.menual_total_planning.polish_weight = parseFloat(parseFloat(this.menual_total_planning.polish_weight) + parseFloat(this.manualPlanning[i]['polish_weight'] ? this.manualPlanning[i]['polish_weight'] : 0)).toFixed(4)
                  this.menual_total_planning.value = parseFloat(parseFloat(this.menual_total_planning.value) + parseFloat(this.manualPlanning[i]['value'] ? this.manualPlanning[i]['value'] : 0))
              }

              console.log(this.sub_part.weight)
              if (this.menual_total_planning.weight > this.sub_part.weight) {
                  swal("Warning!", "Total Planning Weight Must Be less then Issue Weight!", "warning")
                  // var i = this.manualPlanning.length;
                  //     while(i--){
                  //     if(this.manualPlanning[i]['id'] == plan.id ){
                  //         this.manualPlanning[i]['polish_weight'] = ''
                  // return
                  //   }
                  // }
              }
          },
          loadPlanningValuesNew(plan) {
              if (plan.polish_weight != '' && plan.weight != '') {
                  if (plan.polish_weight > plan.weight) {

                      swal("Warning!", "Polish Must Less then Makeable Weight!", "warning")
                      var i = this.manualPlanning.length;
                      while (i--) {
                          if (this.manualPlanning[i]['id'] == plan.id) {
                              this.manualPlanning[i]['polish_weight'] = ''
                              return
                          }
                      }
                  }
              }
              this.getPlanningTotalNew()

              if (plan.polish_weight != '' && plan.color != '' && plan.cut != '' && plan.purity != '' && plan.polish != '' && plan.shape != '' && plan.symmetry != '' && plan.fluerocent != '' && plan.height != '' && plan.ratio != '' && plan.tabl != '') {

                  let shape_name = ''
                  var i = this.diamond.shapes.length;
                  while (i--) {
                      if (this.diamond.shapes[i]['id'] == plan.shape) {
                          shape_name = this.diamond.shapes[i]['shape_group']
                      }
                  }
                  let params = {
                      color: plan.color,
                      cut: plan.cut,
                      purity: plan.purity,
                      polish: plan.polish,
                      symmetry: plan.symmetry,
                      fluerocent: plan.fluerocent,
                      tension: plan.tension,
                      height: plan.height,
                      ratio: plan.ratio,
                      tabl: plan.tabl,
                      shape: plan.shape,
                      shape_group: shape_name,
                      polish_weight: plan.polish_weight,
                      stone: this.stone,
                  }
                  axios({
                      url: 'api/plan/get_plan_values',
                      method: 'post',
                      baseURL: BASE_URL,
                      data: params,
                      headers: {
                          Authorization: 'Bearer ' + localStorage.getItem('token')
                      },
                      config: {
                          headers: {
                              'Accept': 'application/json'
                          }
                      }
                  }).then((res) => {
                      let rap_value = res.data.rape_value
                      let back = res.data.back

                      var i = this.manualPlanning.length;
                      while (i--) {
                          if (this.manualPlanning[i]['id'] == plan.id) {
                              this.manualPlanning[i]['r_rate'] = parseFloat(rap_value * plan.polish_weight).toFixed(2)
                              this.manualPlanning[i]['discount'] = back
                              this.manualPlanning[i]['value'] = parseFloat(this.manualPlanning[i]['r_rate'] - (this.manualPlanning[i]['r_rate'] * back / 100)).toFixed(2)
                          }
                      }
                      this.getPlanningTotalNew()
                  })
              }

          },
          addNewPlanning(id) {

              this.manualPlanning = [{
                  id: 1,
                  part_no: '',
                  weight: '',
                  polish_weight: '',
                  color: '',
                  cut: '',
                  purity: '',
                  polish: '',
                  shape: '',
                  symmetry: '',
                  fluerocent: '',
                  tension: '',
                  stone: this.packet_item.stone,
                  ratio: '',
                  tabl: '',
                  r_rate: '',
                  value: '',
                  height: '',
                  discount: '',
              }]

              this.getPlanningTotalNew()

              this.total_items = 1
              // } else {

              // }
              // cash('#plan-details-modal').modal('hide')
              cash('#new-manual-planning').modal('show')
          },
          submitManualPlanning() {

              let submit = true
              this.manualPlanning.forEach(plan => {
                  if (plan.weight == '' || plan.polish_weight == '' || plan.color == '' || plan.cut == '' || plan.purity == '' || plan.polish == '' || plan.shape == '' || plan.symmetry == '' || plan.fluerocent == '' || plan.height == '' || plan.ratio == '' || plan.tabl == '') {
                      swal("Warning!", "Fileds are Empty !", "warning")
                      submit = false
                  }
              });

              if (submit == true) {

                  this.loading = true
                  let params = {
                      plan_details: this.manualPlanning,
                      packet_id: this.packet_item.id,
                      menual_total: this.menual_total_planning,
                  }

                  axios({
                      url: 'api/plan/add_ls_sub_pieces',
                      method: 'post',
                      baseURL: BASE_URL,
                      data: params,
                      headers: {
                          Authorization: 'Bearer ' + localStorage.getItem('token')
                      },
                      config: {
                          headers: {
                              'Accept': 'application/json'
                          }
                      }
                  }).then((res) => {

                    this.loading = false

                    if(res.data.status == 'error') {
                      this.$swal("Warning!", res.data.message, "warning")
                    } else {

                      cash('#new-manual-planning').modal('hide')
                      this.manualPlanning = [{
                          id: 1,
                          part_no: '',
                          weight: '',
                          polish_weight: '',
                          color: '',
                          cut: '',
                          purity: '',
                          polish: '',
                          shape: '',
                          symmetry: '',
                          fluerocent: '',
                          tension: '',
                          stone: '',
                          ratio: '',
                          tabl: '',
                          r_rate: '',
                          value: '',
                          height: '',
                          discount: '',
                      }]
                      this.total_items = 1


                      this.menual_total_planning.polish_weight = 0
                      this.menual_total_planning.weight = 0
                      this.menual_total_planning.value = 0
                      this.scanAndAddPack()
                      this.$swal("Success!", res.data.message, "success")
                    }

                  }).catch((err) => {
                    this.loading = false
                    this.$swal("Warning!", "Something went wrong.", "warning")
                  })
              }

          },
          update_print_status(item) {

            let params = {
              packet_id: window.btoa(item.id)
            }

            axios({
                url: 'api/process/update_print_status',
                method: 'post',
                baseURL: BASE_URL,
                data: params,
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
                config: {
                    headers: {
                        'Accept': 'application/json'
                    }
                }
            }).then((res) => {

              if(res.data.status == 'error') {

                this.$swal("Warning!", res.data.message, "warning")
              } else if(res.data.status == 'success') {

                item.print_status = false
              }
            }).catch((err) => {
              this.loading = false
              this.$swal("Warning!", "Something went wrong.", "warning")
            })

          }
      },
      //   watch: {
      //   // whenever question changes, this function will run
      //   scan_lot_no(newQuestion, oldQuestion) {
      //     if (this.scan_lot_no != '') {
      //       this.scanAndAddPack()
      //     }
      //   }
      // },

  })
  </script>
