<template>
  <div>
    <!-- Headings -->
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Stock Tally Report</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <input id="tabulator-html-filter-value" v-model="page_data.search" type="text"
              class="form-control w-full mt-2 sm:mt-0" ref="focusMe"
                  @change="getPacketDetailsScan" placeholder="Scan Barcode/ Packet" autofocus/>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">

      <div class="grid grid-cols-12 gap-x-2">
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div class="mt-2">
            <label for="update-profile-form-6" class="form-label">Packet / Lot</label>
            <input id="tabulator-html-filter-value" v-model="filter.packet_no" type="text"
              class="form-control w-full" placeholder="Search Packet / Kapan" />
          </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div class="mt-2">
            <label for="update-profile-form-6" class="form-label">Select Kapan</label>
            <TomSelect v-model="filter.kapan_id" :options="{
              placeholder: 'Select Kapan',
              loadingClass: 'loading',
              plugins: {
                dropdown_header: {
                  title: 'Process'
                }
              }
            }" class="w-full">
              <option value="0">Select Kapan</option>
              <option v-for="item in page_data.lot_items" :key="item.id" :value="item.id">{{ item.lot_no }} ( {{
                item.voucher_no ? item.voucher_no : '-' }} )</option>
            </TomSelect>
          </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
            <label for="update-profile-form-6" class="form-label py-1">Select Process</label>
            <TomSelect v-model="filter.process_id" @change="getProcessChange" :options="{
              placeholder: 'Select Process',
              loadingClass: 'loading',
              plugins: {
                dropdown_header: {
                  title: 'Process'
                }
              }
            }" class="w-full">
              <option value="0">Select Process</option>
              <option value="stock">In Stock</option>
              <option v-for="process in page_data.processes" :key="process.id" :value="process.id">
                {{ process.process_name }} ( {{ process.process_short_code ? process.process_short_code : '-' }} )
              </option>
            </TomSelect>
          </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2" v-if="user_details.user_role == 1">
          <div>
            <label for="update-profile-form-6" class="form-label py-1">Select Manager</label>
            <TomSelect v-model="filter.manager_id" @change="getEmployeeDetails" :options="{
              placeholder: 'Select Manager',
              loadingClass: 'loading',
              plugins: {
                dropdown_header: {
                  title: 'Manager'
                }
              }
            }" class="w-full">
              <option value="0">Select Manager</option>
              <option v-for="manager in page_data.managers" :key="manager.id" :value="manager.id">{{ manager.first_name }}
                {{ manager.last_name }} ( {{ manager.short_code ? manager.short_code : '-' }} )</option>
            </TomSelect>
          </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
            <label for="update-profile-form-6" class="form-label py-1">Employee Type</label>
            <TomSelect v-model="filter.employee_type" @change="getEmployeeDetails" :options="{
              placeholder: 'Select Employee Type',
              loadingClass: 'loading',
              plugins: {
                dropdown_header: {
                  title: 'Employee Type'
                }
              }
            }" class="w-full">
              <option value="0">Karigar Type</option>
              <option value="1">Employee</option>
              <option value="2">Planner / Checker</option>
              <option value="3">Jobwork Party</option>
            </TomSelect>
          </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2" v-if="filter.employee_type != '0'">
          <div>
            <label for="update-profile-form-6" class="form-label py-1">Select Employee</label>
            <TomSelect v-model="filter.employee_id" :options="{
              placeholder: 'Select Employee',
              loadingClass: 'loading',
              plugins: {
                dropdown_header: {
                  title: 'Employee'
                }
              }
            }" class="w-full">
              <option value="0">Select Employee</option>
              <option v-for="employee in page_data.employees" :key="employee.id" :value="employee.id">
                {{ employee.first_name }} {{ employee.last_name }} ( {{ employee.short_name ? employee.short_name : '-' }}
                )
              </option>
            </TomSelect>
          </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
            <label for="update-profile-form-6" class="form-label py-1">Select Type</label>
            <TomSelect v-model="filter.type" :options="{
              placeholder: 'Select Type',
              loadingClass: 'loading',
              plugins: {
                dropdown_header: {
                  title: 'Type'
                }
              }
            }" class="w-full">
              <option value="0">Select Type</option>
              <option value="makeable">Makeable</option>
              <option value="rough">Rough</option>
              <option value="video">Video</option>
            </TomSelect>
          </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
            <label for="update-profile-form-6" class="form-label py-1">Stock Type</label>
            <TomSelect v-model="filter.stock_type" :options="{
              placeholder: 'Stock Type',
              loadingClass: 'loading',
              plugins: {
                dropdown_header: {
                  title: 'Stock Type'
                }
              }
            }" class="w-full">
              <option value="0">Stock Type</option>
              <option value="1">Hold</option>
              <option value="2">Active</option>
            </TomSelect>
          </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
            <label for="update-profile-form-6" class="form-label py-1">Process Done Stock</label>
            <TomSelect v-model="filter.process_done" @change="getProcessChange" :options="{
              placeholder: 'Select Process',
              loadingClass: 'loading',
              plugins: {
                dropdown_header: {
                  title: 'Process'
                }
              }
            }" class="w-full">
              <option value="0">Select Process</option>
              <option v-for="process in page_data.processes" :key="process.id" :value="process.id">
                {{ process.process_name }} ( {{ process.process_short_code ? process.process_short_code : '-' }} )
              </option>
            </TomSelect>
          </div>
        </div>
        <div class="col-span-6 md:col-span-2 xl:col-span-2">
          <div class="mt-5 flex pt-4">
            <input id="remember-me" type="checkbox" v-model="filter.for_p"
              class="form-check-input border mr-2 bg-white" />
            <label for="remember-me" class="text-md font-medium">4P</label>
          </div>
          <div>
            <input id="remember-me" type="checkbox" v-model="filter.semi_polish"
              class="form-check-input border mr-2 bg-white" />
            <label for="remember-me" class="text-md font-medium">Semi Polish</label>
          </div>
        </div>
        <div class="col-span-12 sm:col-span-12 md:col-span-12 xl:col-span-8">
          <div class="mt-5 flex justify-end pt-4">

            <button id="tabulator-html-filter-reset" type="button" class="btn btn-secondary btn-sm py-2 ml-2"
              @click="onResetFilter">
              Reset
            </button>
            <button id="tabulator-html-filter-go" type="button" class="btn btn-primary btn-sm py-2 ml-2" tabindex="5"
              @click="generateReport()">
              Generate
            </button>
            <!-- <button id="tabulator-html-filter-go" type="button" class="btn btn-primary btn-sm py-2 ml-2" tabindex="5"
              @click="generateReport()">
              Export
            </button> -->
            <export-excel
                  class   = "btn btn-primary btn-sm py-2 ml-2"
                  :data   = "page_data.items"
                  :fields = "page_data.json_fields"
                  worksheet = "Stock Telly"
                  name    = "stock_telly_report.xls">

                  Excel Export

              </export-excel>
          </div>
        </div>
      </div>

      <div id="responsive-table" class="p-5" v-if="page_data.loading">
        <div class="preview">
          <div class="overflow-x-auto">
            <div
              class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
            >
              <LoadingIcon icon="bars" class="w-8 h-8" />
              <div class="text-center text-xs mt-2">Getting Latest Records</div>
          </div>
          </div>
        </div>
      </div>
      <div v-else>

        <div>
          <div class="scrollbar-hidden">
            <div class="intro-y col-span-12 overflow-auto">
              <table class="w-full mt-3 border-2">
                <thead>
                  <tr class="border-b bg-gray-300 dark:bg-dark-1">
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Tally</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Packet</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Stock No</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Proc</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Type</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Dept</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Mgr</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Emp</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">R Wt.</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Iss Wt.</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">PL Wt.</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Shape</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Color</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Purity</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Cut</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Polish</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Symm</th>
                    <th class="py-2 text-xs lg:text-md">Iss Date</th>
                    <th class="py-2 text-xs lg:text-md whitespace-nowrap">Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="border-b bg-gray-200 dark:bg-dark-1">
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                    <!-- <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td> -->
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.total_packets ? page_data.total.total_packets : '-' }}</td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.rough_weight ? $h.myFloat(page_data.total.rough_weight) + ' Ct' : '-' }}</td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.issue_weight ? $h.myFloat(page_data.total.issue_weight) + ' Ct' : '-' }}</td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.polish_weight ? $h.myFloat(page_data.total.polish_weight) + ' Ct' : '-' }}</td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                  </tr>
                  <tr class="border-b bg-gray-200 dark:bg-dark-1">
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                    <!-- <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td> -->
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.tally_total.total_packets ? page_data.tally_total.total_packets : '-' }}</td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.tally_total.rough_weight ? $h.myFloat(page_data.tally_total.rough_weight) + ' Ct' : '-' }}</td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.tally_total.issue_weight ? $h.myFloat(page_data.tally_total.issue_weight) + ' Ct' : '-' }}</td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.tally_total.polish_weight ? $h.myFloat(page_data.tally_total.polish_weight) + ' Ct' : '-' }}</td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                  </tr>
                  <tr v-for="(single_item, ind) in page_data.items" :key="ind" class="border-b hover:bg-gray-200 p-0"
                    :class="{ 'bg-gray-100': (ind % 2) == 0 }">
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r">
                      <div v-if="single_item.tally_status == false" class="text-center p-1 rounded-md bg-primary-6 w-8 m-auto"> <InfoIcon class="w-4 h-4 text-white" /> </div>
                      <div v-if="single_item.tally_status == true" class="text-center p-1 rounded-md bg-primary-4 w-8 m-auto"> <CheckIcon class="w-4 h-4 text-white" /> </div>
                    </td>
                    <!-- <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ ind + 1 }}</td> -->
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.number }}</td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.vepari_no }}</td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.process_short_code ? single_item.process_short_code : '-' }}</td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.packet_type ? single_item.packet_type : '-' }}</td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.department_short_code ? single_item.department_short_code : '-' }}</td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.manager_short_name ? single_item.manager_short_name : '-' }}</td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.employee_short_name ? single_item.employee_short_name : '-' }}</td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.weight ? $h.myFloat(single_item.weight) + ' Ct' : '-' }}</td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.issue_weight ? $h.myFloat(single_item.issue_weight) + ' Ct' : '-' }}</td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.cr_polish_weight ? $h.myFloat(single_item.cr_polish_weight) + ' Ct' : '-' }}</td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.shape ? single_item.shape : '-' }}</td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.color ? single_item.color : '-' }}</td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.purity ? single_item.purity : '-' }}</td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.cut ? single_item.cut : '-' }}</td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.polish ? single_item.polish : '-' }}</td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.symmetry ? single_item.symmetry : '-' }}</td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ single_item.issue_date ? single_item.issue_date : '-' }}</td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r"><span
                        v-if="single_item.transaction_status == 1" class="text-theme-11">Hold</span>
                      <span v-if="single_item.transaction_status == 2" class="text-theme-1">Active</span>
                    </td>
                  </tr>
                  <tr class="border-b bg-gray-200 dark:bg-dark-1">
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                    <!-- <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td> -->
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.total_packets ? page_data.total.total_packets : '-' }}</td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.rough_weight ? $h.myFloat(page_data.total.rough_weight) + ' Ct' : '-' }}</td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.polish_weight ? $h.myFloat(page_data.total.polish_weight) + ' Ct' : '-' }}</td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.issue_weight ? $h.myFloat(page_data.total.issue_weight) + ' Ct' : '-' }}</td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                    <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                  </tr>
                </tbody>

              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: HTML Table Data -->

  </div>
</template>
<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config';
import axios from 'axios';
import moment from 'moment';
import { defineComponent, onMounted, reactive, toRef, watch, computed, ref, inject } from 'vue';
import MultipleSelect from '@/components/MultipleSelect/Main.vue'
import store from '@/store'
import {
      helper as $h
  } from '@/utils/helper'

export default defineComponent({
  components: {
    MultipleSelect
  },
  setup() {
    const user_details = computed(() => store.state.user.currentUser)
    const focusMe = ref()
    const swal = inject('$swal')
    const page_data = reactive({
      check: false,
      search: '',
      total: [],
      tally_total: {
        "total_packets": 0,
        "rough_weight": 0,
        "polish_weight": 0,
        "issue_weight": 0
      },
      json_fields: {
        'Telly': {
                field: 'tally_status',
                callback: (value) => {
                    return (value  == true ? "Telly"  : "Pending")
                }
        },
        'Packet': "number",
        'Stock No': "vepari_no",
        'Proc': "process_short_code",
        'Type': "packet_type",
        'Dept': "department_short_code",
        'Mgr': "manager_short_name",
        'Emp': "employee_short_name",
        'Iss Wt.': "weight",
        'PL Wt.': "cr_polish_weight",
        'R Wt.': "issue_weight",
        'Shape': "shape",
        'Color': "color",
        'Purity': "purity",
        'Cut': "cut",
        'Polish': "polish",
        'Symm': "symmetry",
        'Iss Date': "issue_date",
        'Status': {
                field: 'transaction_status',
                callback: (value) => {
                    return (value  == 1 ? "Pending"  : "Active")
                }
          },
      },
      items: [],
      parties: [],
      managers: [],
      processes: [],
      lot_items: [],
      departments: [],
      employees: [],
      loading: false,
      pagelinks: [],
      prev_page_url: null,
      next_page_url: null,
      first_page_url: null,
      last_page_url: null,
      current_page: 1,
      last_page: '',
      parameters: {
        color: [],
        cut: [],
        purity: [],
        polish: [],
        shape: [],
        symmetry: [],
        fluerocent: [],
        tension: [],
        stone: []
      }
    })
    const filter = reactive({
      packet_no: '',
      party_id: '0',
      kapan_id: '0',
      process_id: '0',
      process_done: '0',
      department_id: '0',
      manager_id: '0',
      employee_id: '0',
      employee_type: '0',
      stock_type: '0',
      type: '0',
      for_p: false,
      semi_polish: false,
    })
    const api_filter = reactive({
      packet_no: '',
      party_id: '0',
      kapan_id: '0',
      process_id: '0',
      process_done: '0',
      department_id: '0',
      manager_id: '0',
      employee_id: '0',
      employee_type: '0',
      stock_type: '0',
      type: '0',
      for_p: false,
      semi_polish: false,
    })
    function getReportDetails() {
      api_filter.packet_no = filter.packet_no
      api_filter.party_id = filter.party_id
      api_filter.kapan_id = filter.kapan_id
      api_filter.process_id = filter.process_id
      api_filter.process_done = filter.process_done
      api_filter.department_id = filter.department_id
      api_filter.manager_id = filter.manager_id
      api_filter.employee_id = filter.employee_id
      api_filter.employee_type = filter.employee_type
      api_filter.for_p = filter.for_p
      api_filter.semi_polish = filter.semi_polish
      api_filter.stock_type = filter.stock_type
      api_filter.type = filter.type
      getReportDetail()
    }
    function getDiamontData() {
      axios({
        url: 'api/master/get_all_parameters',
        method: 'post',
        baseURL: BASE_URL,
        data: filter,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept': 'application/json' } }
      }).then((res) => {
        page_data.parameters.color = res.data.color
        page_data.parameters.cut = res.data.cut
        page_data.parameters.purity = res.data.purity
        page_data.parameters.symmetry = res.data.symmetry
        page_data.parameters.polish = res.data.polish
        page_data.parameters.fluerocent = res.data.fluerocent
        page_data.parameters.tension = res.data.tension
        page_data.parameters.shape = res.data.shape
        page_data.parameters.stone = res.data.stone
      })
    }
    const getReportDetail = () => {
      page_data.loading = true

      let params = {
        packet_no: window.btoa(api_filter.packet_no),
        party_id: api_filter.party_id == '0' ? '' : window.btoa(api_filter.party_id),
        kapan_id: api_filter.kapan_id == '0' ? '' : window.btoa(api_filter.kapan_id),
        process_id: api_filter.process_id == '0' ? '' : window.btoa(api_filter.process_id),
        process_done: api_filter.process_done == '0' ? '' : window.btoa(api_filter.process_done),
        department_id: api_filter.department_id == '0' ? '' : window.btoa(api_filter.department_id),
        manager_id: api_filter.manager_id == '0' ? '' : window.btoa(api_filter.manager_id),
        employee_id: api_filter.employee_id == '0' ? '' : window.btoa(api_filter.employee_id),
        employee_type: api_filter.employee_type == '0' ? '' : window.btoa(api_filter.employee_type),
        stock_type: api_filter.stock_type == '0' ? '' : window.btoa(api_filter.stock_type),
        type: api_filter.type == '0' ? '' : window.btoa(api_filter.type),
        for_p: api_filter.for_p,
        semi_polish: api_filter.semi_polish
      }

      let promise = axios({
        url: 'api/report/get_stock_tally_report',
        method: "post",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          page_data.items = data.items
          page_data.total = data.total
          page_data.loading = false
          focusMe.value.focus()
        })
        .catch(_error => {
          page_data.items = []
          page_data.total = []
          page_data.loading = false
          focusMe.value.focus()
        });
    }
    const getPartyDetails = () => {

      let params = {
        // department_id : window.btoa(filter.department_id)
      }
      let api_url = 'api/master/dp_parties'
      let promise = axios({
        url: api_url,
        method: "post",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          page_data.parties = data.items
        })
        .catch(_error => {
          page_data.parties = []
        });
    }
    const getDepartmentDetails = () => {
      let params = {
        // department_id : window.btoa(filter.department_id)
      }
      let api_url = 'api/master/dp_department'
      let promise = axios({
        url: api_url,
        method: "post",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          page_data.departments = data.items
        })
        .catch(_error => {
          page_data.departments = []
        });
    }
    const getManagerDetails = () => {
      let params = {
        department_id: filter.department_id == '0' ? '' : window.btoa(filter.department_id),
        process_id: filter.process_id == '0' ? '' : window.btoa(filter.process_id)
      }
      let api_url = ''

      if (filter.department_id == '0' && filter.process_id == '0') {

        api_url = 'api/master/dp_managers'
      } else {
        api_url = 'api/master/get_manager_by_department'
      }
      let promise = axios({
        url: api_url,
        method: "post",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          filter.manager_id = '0'
          page_data.managers = data.items
        })
        .catch(_error => {
          filter.manager_id = '0'
          page_data.managers = []
        });
    }
    const getProcessDetails = () => {
      let params = {
        id: window.btoa(filter.manager_id)
      }
      let api_url = ''

      if (filter.manager_id == '0') {

        api_url = 'api/master/dp_process'
      } else {
        api_url = 'api/master/dp_process'
      }
      let promise = axios({
        url: api_url,
        method: "post",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          filter.process_id = '0'
          page_data.processes = data.items
        })
        .catch(_error => {
          filter.process_id = '0'
          page_data.processes = []
        });
    }
    const getEmployeeDetails = () => {

      let params = {
        manager_id: user_details.value.user_role == 1 ? (filter.manager_id == '0' ? '' : window.btoa(filter.manager_id)) : user_details.value.account_id,
        process_id: filter.process_id == '0' ? '' : window.btoa(filter.process_id),
        employee_type: filter.employee_type == '0' ? '' : window.btoa(filter.employee_type),
      }
      let api_url = ''

      if (filter.department_id == '0') {

        api_url = 'api/master/get_employees_by_manager_employee_type'
      } else {
        api_url = 'api/master/get_employees_by_manager_employee_type'
      }
      let promise = axios({
        url: api_url,
        method: "post",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          filter.employee_id = '0'
          page_data.employees = data.items
        })
        .catch(_error => {
          filter.employee_id = '0'
          page_data.employees = []
        });
    }
    const getLotDetails = async () => {
      let params = {
      }
      let api_url = 'api/master/dp_lot_all_details'

      let promise = axios({
        url: api_url,
        method: "post",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: {
          headers: {
            Accept: "application/json"
          }
        }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          filter.kapan_id = '0'
          page_data.lot_items = data.items
        })
        .catch(_error => {
          filter.kapan_id = '0'
          page_data.lot_items = []
        });
    }
    function onResetFilter() {
      filter.packet_no = ''
      filter.party_id = '0'
      filter.kapan_id = '0'
      filter.process_id = '0'
      filter.department_id = '0'
      filter.manager_id = '0'
      filter.employee_id = '0'
      filter.employee_type = '0'
      filter.stock_type = '0'
      filter.type = '0'
      filter.process_done = '0'
      filter.issue_pending = true
      filter.for_p = true
    }
    function getPacketDetailsScan() {
      if(!page_data.check && page_data.search) {
        scanAndAddPacket();
      }
    }
    function scanAndAddPacket() {
      if(page_data.search != '' && page_data.search != null){

        let is_match_found = false;
        let is_added_found = false;

        let length = page_data.items.length

        for (let index = 0; index < length; index++) {

          if(page_data.items[index]['tally_status'] == true) {
            is_added_found = true;
          } else {

            if(page_data.items[index]['barnum'] == page_data.search || page_data.items[index]['number'] == page_data.search) {
              page_data.items[index]['tally_status'] = true;
              is_match_found = true;
              page_data.search = ''

              page_data.tally_total.total_packets += 1;
              page_data.tally_total.rough_weight = $h.myFloat(parseFloat(page_data.tally_total.rough_weight) + parseFloat(page_data.items[index]['weight'] ? page_data.items[index]['weight'] : 0));
              page_data.tally_total.polish_weight = $h.myFloat(parseFloat(page_data.tally_total.polish_weight) + parseFloat(page_data.items[index]['cr_polish_weight'] ? page_data.items[index]['cr_polish_weight'] : 0));
              page_data.tally_total.issue_weight = $h.myFloat(parseFloat(page_data.tally_total.issue_weight) + parseFloat(page_data.items[index]['issue_weight'] ? page_data.items[index]['issue_weight'] : 0));

              page_data.items.sort((a, b) => {
                  return a.tally_status - b.tally_status;
              });

              return;
            }
          }
        }

        if(is_added_found == false) {
          swal("Warning!", "Stock No already scanned !", "warning")
        } else if(is_match_found == false) {
          swal("Warning!", "Stock Details Not Found !", "warning")
        }

        focusMe.value.focus()
      }
    }
    function generateReport() {
      getReportDetails()
    }
    function getProcessChange() {
      if (user_details.value.user_role == 1) {
        getManagerDetails()
      } else {
        getEmployeeDetails()
      }
    }
    onMounted(() => {
      getPartyDetails()
      getDepartmentDetails()
      getManagerDetails()
      getProcessDetails()
      getDiamontData()
      getLotDetails()
    })
    return {
      page_data,
      focusMe,
      filter, api_filter, user_details, generateReport,
      getProcessDetails,
      getManagerDetails,
      onResetFilter,
      getEmployeeDetails,
      getProcessChange,
      getLotDetails,
      getPacketDetailsScan
    }
  }
})
</script>
