<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Process Master</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
          <router-link
          :to="{ name: 'process-create' }"
          tag="button"
           class="btn btn-primary shadow-md mr-2">

         New Process
          </router-link>

          <!-- <a
          href="javascript:;"
          data-toggle="modal"
          data-target="#new-order-modal"
          class="btn btn-primary shadow-md mr-2"
          >New Process</a
        > -->

      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <form id="tabulator-html-filter-form" class="xl:flex sm:mr-auto">
          <!-- <div class="sm:flex items-center sm:mr-4">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
              >Field</label
            >
            <select
              id="tabulator-html-filter-field"
              v-model="filter.field"
              class="form-select w-full sm:w-32 2xl:w-full mt-2 sm:mt-0 sm:w-auto"
            >
              <option value="process_name">Process Name</option>
              <option value="process_code">Code</option>
              <option value="process_order">Order</option>
            </select>
          </div>
          <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
              >Type</label
            >
            <select
              id="tabulator-html-filter-type"
              v-model="filter.type"
              class="form-select w-full mt-2 sm:mt-0 sm:w-auto"
            >
              <option value="like" selected>like</option>
              <option value="=">=</option>
              <option value="<">&lt;</option>
              <option value="<=">&lt;=</option>
              <option value=">">></option>
              <option value=">=">>=</option>
              <option value="!=">!=</option>
            </select>
          </div>
          <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
              >Value</label
            >
            <input
              id="tabulator-html-filter-value"
              v-model="filter.value"
              type="text"
              class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
              placeholder="Search..."
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full sm:w-16"
              @click="onFilter"
            >
              Go
            </button>
            <button
              id="tabulator-html-filter-reset"
              type="button"
              class="btn btn-secondary w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1"
              @click="onResetFilter"
            >
              Reset
            </button>
          </div> -->
        </form>
        <div class="flex mt-5 sm:mt-0">

          <div class="dropdown w-1/2 sm:w-auto">
            <button
              class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto"
              aria-expanded="false"
            >
              <FileTextIcon class="w-4 h-4 mr-2" /> Export
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-menu w-40">
              <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                <a
                  id="tabulator-export-csv"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportCsv"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export CSV
                </a>
                <a
                  id="tabulator-export-json"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportJson"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export JSON
                </a>
                <a
                  id="tabulator-export-xlsx"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportXlsx"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export XLSX
                </a>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>

</template>

<script>
/* eslint-disable */
import { defineComponent, ref,onMounted } from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import { useRouter, useRoute } from 'vue-router'
import { BASE_URL } from '@/constants/config.js'

export default defineComponent({
  setup() {
    const router = useRouter()
    const route = useRoute()
    const tableRef = ref()
    const tabulator = ref()

    const initTabulator = () => {
      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: BASE_URL + 'api/master/get_process_details',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 20,
        paginationSizeSelector: [10, 20, 30, 40],
        layout: 'fitColumns',
        headerSort: false,
        responsiveLayout: 'collapse',
        placeholder: 'No matching records found',
        columns: [
          // For HTML table
          {
            title: 'Proc Name',
            minWidth: 100,
            responsive: 0,
            field: 'process_name',
            vertAlign: 'middle',
            print: false,
            download: false,

          },

          {

            title: 'Proc Code',
            minWidth: 100,
            field: 'process_code',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {

            title: 'Short Code',
            minWidth: 100,
            field: 'process_short_code',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {

          title: 'Type',
          minWidth: 100,
          field: 'process_type',
          vertAlign: 'middle',
          print: false,
          download: false
          },
          {

          title: 'Rec Type',
          minWidth: 100,
          field: 'transaction_type',
          vertAlign: 'middle',
          print: false,
          download: false,
          formatter(cell) {
              if(cell.getData().transaction_type == 1) {
                return 'NORMAL'
              } else if(cell.getData().transaction_type == 2) {
                return 'RECUT'
              } else if(cell.getData().transaction_type == 3) {
                return 'PLANNING'
              } else if(cell.getData().transaction_type == 4) {
                return 'CHECKING'
              } else if(cell.getData().transaction_type == 5) {
                return 'FULL PROCESS'
              } else if(cell.getData().transaction_type == 6) {
                return 'HPHT'
              } else if(cell.getData().transaction_type == 7) {
                return 'LAB'
              } else if(cell.getData().transaction_type == 8) {
                return 'Final Polish'
              } else if(cell.getData().transaction_type == 9) {
                return 'LS'
              }
            }
          },
          {

          title: 'Limit',
          minWidth: 100,
          field: 'mc',
          vertAlign: 'middle',
          print: false,
          download: false
          },
          {

          title: 'Duration',
          minWidth: 100,
          field: 'duration',
          vertAlign: 'middle',
          print: false,
          download: false
          },
          {

          title: 'Labout',
          minWidth: 100,
          field: 'labour',
          vertAlign: 'middle',
          print: false,
          download: false,
          formatter(cell) {
              if(cell.getData().labour == 1) {
                return 'Issue Weight'
              } else if(cell.getData().labour == 2) {
                return 'Fixed Piece'
              } else if(cell.getData().labour == 3) {
                return 'Weight + Weight'
              } else if(cell.getData().labour == 4) {
                return 'Piece + Piece'
              } else if(cell.getData().labour == 5) {
                return 'Weight Loss'
              }
            }
          },
          // {
          //   title: 'Order',
          //   minWidth: 50,
          //   field: 'process_order',
          //   vertAlign: 'middle',
          //   print: false,
          //   download: false
          // },
          // {

          //   title: 'Proc Manager',
          //   minWidth: 200,
          //   field: 'manager',
          //   vertAlign: 'middle',
          //   print: false,
          //   download: false
          // },{

          //   title: 'Proc Jober',
          //   minWidth: 200,
          //   field: 'jober',
          //   vertAlign: 'middle',
          //   print: false,
          //   download: false
          // },
          {
            title: 'Status',
            minWidth: 100,
            field: 'active_status',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div class="flex lg:justify-center ${
                cell.getData().active_status == 1 ? 'text-theme-9' : 'text-theme-6'
              }">
                <i data-feather="check-square" class="w-4 h-4 mr-2"></i> ${
                  cell.getData().active_status == 1 ? 'Active' : 'Inactive'
                }
              </div>`
            }
          },
          {
            title: ' ',
            minWidth: 50,
            field: 'actions',
            responsive: 1,
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              const a = cash(`<div class="flex lg:justify-center items-center">
                <a class="flex items-center mr-3" href="javascript:;">
                  <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Edit
                </a>

              </div>`)
              cash(a).on('click', function() {
                //getDetails(cell.getData().id);
                router.push("/master/process/update/" + window.btoa(cell.getData().id));
              })

              return a[0]
            }
          },

          // For print format
          {
            title: 'Process Name',
            field: 'process_name',
            visible: false,
            print: true,
            download: true
          },
          {
            title: 'Process Code',
            field: 'process_code',
            visible: false,
            print: true,
            download: true
          },
          {
            title: 'Short Code',
            field: 'process_short_code',
            visible: false,
            print: true,
            download: true
          },
          {

          title: 'Type',
          field: 'process_type',
          visible: false,
            print: true,
            download: true
          },
          {

          title: 'Limit',
          field: 'mc',
          visible: false,
            print: true,
            download: true
          },
          {

          title: 'Duration',
          field: 'duration',
          visible: false,
            print: true,
            download: true
          },
          {

          title: 'Labout',
          field: 'labour',
          visible: false,
            print: true,
            download: true,
          formatter(cell) {
              if(cell.getData().labour == 1) {
                return 'Issue Weight'
              } else if(cell.getData().labour == 2) {
                return 'Fixed Piece'
              } else if(cell.getData().labour == 3) {
                return 'Weight + Weight'
              } else if(cell.getData().labour == 4) {
                return 'Piece + Piece'
              } else if(cell.getData().labour == 5) {
                return 'Weight Loss'
              }
            }
          },
          {
            title: 'Status',
            field: 'status',
            visible: false,
            print: true,
            download: true,
            formatterPrint(cell) {
              return cell.getValue() ? 'Active' : 'Inactive'
            }
          },

        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }


    // Export
    const onExportCsv = () => {
      tabulator.value.download('csv', 'data.csv')
    }

    const onExportJson = () => {
      tabulator.value.download('json', 'data.json')
    }

    const onExportXlsx = () => {
      const win = window
      win.XLSX = xlsx
      tabulator.value.download('xlsx', 'data.xlsx', {
        sheetName: 'Products'
      })
    }

    const onExportHtml = () => {
      tabulator.value.download('html', 'data.html', {
        style: true
      })
    }

    // Print
    const onPrint = () => {
      tabulator.value.print()
    }

    onMounted(() => {
      initTabulator()
      reInitOnResizeWindow()
    })


    return {
      tableRef,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint
    }
  }
})
</script>
